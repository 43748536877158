import React, { lazy, Suspense } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

import BoxTicket from "../components/box-ticket";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";
import classNames from "classnames";

const Spline = lazy(() => import("@splinetool/react-spline"));

const Ticket = () => {
  const { ticketsJson } = useStaticQuery(
    graphql`
      query {
        ticketsJson {
          tickets {
            id
            label
            date
            price
            discount
            list {
              name
            }
          }
        }
      }
    `
  );
  return (
    <Layout>
      <Seo
        title={`Acquista ticket`}
        description={`Acquista il tuo biglietto per la giornata dell'evento.`}
      />

      <div className="box-ticket-wrap">
        <Suspense>
          <Spline
            scene="https://prod.spline.design/LH1fpiv595HjQm6T/scene.splinecode"
            className="animation-circle"
          />
        </Suspense>
        <div className="box-ticket-wrap__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 text-center">
                <div className="box-ticket-wrap__title">Ticket</div>
                {/* <p>Acquista il tuo biglietto nella formula che preferisci.</p>
                <p>
                  Per iscrizioni multiple in giorni diversi, contattare la segreteria organizzativa
                  alla casella{" "}
                  <a href="mailto:info@intranetitalia.it" title="Info IID">
                    info@intranetitalia.it
                  </a>
                </p> */}
                <p>Acquista il tuo biglietto per partecipare all'evento.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container-md">
          <div className="row">
            {ticketsJson.tickets.map((item, index) => (
              <div
                className={classNames("col-12 col-md-6 col-lg-4", {
                  "offset-md-3 offset-lg-4": index === 0,
                })}
                key={item.id}
              >
                <BoxTicket item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default Ticket;
